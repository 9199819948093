import '../styles/style.scss';
import React from 'react';

export default class OverlayTimerMarker extends React.Component {

  div = React.createRef(null);
  bar = React.createRef(null);

  componentDidMount = ()=>{
    
    this.div.current.style.width =  `${this.props.width}px`;
    this.bar.current.style.width =  `${this.props.width * this.props.per}px`;
  }

  render =()=>{

    if(this.bar.current)
    {
      this.div.current.style.width =  `${this.props.width}px`;
      this.bar.current.style.width =  `${this.props.width * this.props.per}px`;
    }
      

    return (
      <div ref={this.div} className='overlay-timer-marker'>
        <div ref={this.bar} className='bar'></div>
      </div>
    )
  }

}