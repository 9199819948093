import '../styles/style.scss';
import React from 'react';
import Map from './Map';
import Overlay from './Overlay';
import CarouselContainer from './CarouselContainer';
import { stores } from '../data/Stores';

export default class App extends React.Component {
  
  state = {currentPoint:0 , showInfo:false}
  map = React.createRef(null);

  componentDidMount = ()=>{

  }

  onLocationChange = (index)=>{

    this.setState({currentPoint:index});
    this.map.current.updateLocation(index);
  }

  onSelectHandler = ()=>{

    this.setState({showInfo:true});
    
  }

  render(){
   return (
     <div className='app'>
       <Map ref={this.map} currentPoint={this.state.currentPoint}/>
       <CarouselContainer onSelectHandler={this.onSelectHandler} onLocationChange={this.onLocationChange} currentPoint={this.state.currentPoint}/>
       {this.getInfoPannel()}
     </div>
   )
 }

 onOverlayClose = ()=>{

  this.setState({showInfo:false});
 }

 getInfoPannel = ()=>{
   if(this.state.showInfo)
    return <Overlay onOverlayClose={this.onOverlayClose} currentPoint={this.state.currentPoint}/>
 }
}


//{this.getImagesForCash()}



