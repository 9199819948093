export const map =  (val,  inMin, inMax,  outMin,  outMax)=>
{
  val = clamp(val);
  return outMin + (outMax - outMin) * ((val - inMin) / (inMax - inMin));
}
export const clamp = (val , min , max)=>{

  if(val  < min)return min;
  else if(val > max)return max;
  else return val;

}
