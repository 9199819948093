import '../styles/style.scss';
import React from 'react';
import OverlayTimer from './OverlayTimer';
import { stores } from '../data/Stores';
import btExit from '../assets/svg/bt-exit.svg'
import ReactPlayer from 'react-player'
import gsap from 'gsap';

export default class Overlay extends React.Component {
 
  
  overlay= React.createRef();
  myCanvas= React.createRef();
  overlayTimer = React.createRef();
  video = React.createRef();
  videoWrapper = React.createRef();
  loader = React.createRef();
  state = {paused:false , currentStory:0 , currentPer:0 , cashImages:[] , loading:false , contentLoaded:false , loadingProgress:0}
  imageTimer = 0;
  storyTime = 10;
  currentTime = 0; 
  intervalTime = 100;
  clickTime;

  componentDidMount = ()=>{

    gsap.to(this.overlay.current , {
      duration:0,
      opacity:0
    })
    gsap.to(this.overlay.current , {
      duration:0.3,
      opacity:1,
      onComplete:()=>{
        //document.addEventListener('click' , this.onOverlayClick);
      }
    })
    this.cashContent();
    this.startStory(0);
  }

  onCLick = (evt)=>{

    this.setState({paused:false});
    const per = evt.changedTouches[0].clientX / window.innerWidth;
    if(per > 0.3)
      this.moveStory(1);
    else
      this.moveStory(-1);
  }

  onMouseUp = (evt)=>{
    this.setState({paused:false});
    const delta = Date.now() - this.clickTime;
    if(delta < 150)
      this.onCLick(evt);
  }

  onMouseDown = (evt)=>{
    this.setState({paused:true});
    this.clickTime = Date.now();
  }

  moveStory = (side)=>{

    this.clearIntervals();
    let next = this.state.currentStory + side;
    if(next < 0)
      next = 0;
    else if(next >= stores[this.props.currentPoint].stories.length)
    {
      this.close();
      return;
    }

    this.setState({currentStory:next ,currentPer:0 });
    this.startStory(next);
  }

  startStory = (story)=>{


    this.setState({contentLoaded:false , paused:false})
    this.enableLoader(true);
    const cStory = stores[this.props.currentPoint].stories[story];

    switch(cStory.type)
    {
      case 'image':
        this.startImageStory();
        break;
      case 'video':
        this.startVideoStory();
        break;
      default:
        break;
    }
     
  }


  startImageStory = ()=>{

    this.imageTimer = setInterval(()=>{

      const totTime = this.storyTime * 1000;
      if(this.currentTime < totTime)
      {
        if(this.state.paused)
          return;

        this.currentTime += this.intervalTime;
        const per = this.currentTime / totTime;
        this.setState({currentPer:per})
      }
      else
        this.endStory();

    } , this.intervalTime);
  }

  startVideoStory = ()=>{
  }

  endStory = ()=>{

    this.clearIntervals();
    if(this.state.currentStory < stores[this.props.currentPoint].stories.length - 1){
      const next = this.state.currentStory + 1;
      this.setState({currentStory:next ,currentPer:0 });
      this.startStory(next);
    }
    else
      this.close();
  }
 
  clearIntervals = ()=>{
    clearInterval(this.imageTimer)

    if(this.video.current){
      this.video.current.player.playing = false;
    }

    this.imageTimer = null;
    this.currentTime = 0;
  }

  close = ()=>{
    this.clearIntervals();
    document.removeEventListener('click' , this.onOverlayClick);

    gsap.to(this.overlay.current , {
      duration:0.2,
      opacity:0,
      onComplete:()=>{
        this.props.onOverlayClose();
      }
    })    
  }

 

  render(){
   return (
     <div ref={this.overlay} className='overlay'>
      {this.getLoder()}
       <div className='menu'>
        <OverlayTimer 
        ref={this.overlayTimer} 
        paused={this.state.paused} 
        currentStory={this.state.currentStory} 
        currentPer={this.state.currentPer} 
        stories={stores[this.props.currentPoint].stories}/> 
        <img src={btExit} onClick={this.close}  className="bt-exit" alt='button exit'></img>
       </div>
       <div className='overlay-hit-area' onTouchStart={this.onMouseDown} onTouchEnd={this.onMouseUp} ></div>
       {this.getContent()}
       <div>{this.state.cashImages}</div>
     </div>
   )
 }

 getLoder = ()=>{

  if(this.state.loading)
   return(
    <div ref={this.loader} className="loader">
    <svg version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="25 25 50 50">
      <circle cx="50" cy="50" r="5" fill="none" strokeWidth="0.4" stroke="#4C4C4C" strokeLinecap="round" strokeDashoffset="0" strokeDasharray="100, 200"></circle>
      <circle cx="50" cy="50" r="5" fill="none" strokeWidth="0.4" stroke="#FFFFFF" strokeLinecap="round" strokeDashoffset="0" strokeDasharray="100, 200">
        <animate attributeName="stroke-dasharray" values="0,200;110,200;110,200" dur="50s" repeatCount="indefinite"/>
      </circle>
      <animate attributeName="opacity" from="0" to="1" dur="0.2s"/>
    </svg>
   </div> 
   )
 }

 

 cashContent = ()=>{
  
  const arr = stores[this.props.currentPoint].stories.map((s , i)  =>{
  
      if(s.type === 'image')
        return <img className='load-image' src={s.file} alt="load" key={`${i}`}></img>
      else
        return <img className='load-image' src={s.bufferImage} alt="load" key={`${i}`}></img>

  });  

  this.setState({cashImages:arr});

 }

 
  onLoadComplete = ()=>{
    
    this.enableLoader(false);
  }

  onBuffer = ()=>{
    this.enableLoader(true);
  }

  enableLoader = (value)=>{
    

    this.setState({loading:value});
    
  }


 getContent = ()=>{

    const cStory = stores[this.props.currentPoint].stories[this.state.currentStory];
    switch(cStory.type)
    {
      case 'image':
        return <img onLoad={this.onLoadComplete} onMouseDown={this.onMouseDown} className='stories-image' src={cStory.file} alt="story"></img>
      case 'video':

          const originalWidth = 1080;
          const originalHeight = 1920;
          const width = this.overlay.current.offsetWidth;
          const per = (width / originalWidth);

          const w = per * originalWidth;
          const h = per * originalHeight;


          const marginTop = -(h - this.overlay.current.offsetHeight) * 0.8;

          return (

              
              <div ref={this.videoWrapper} style={{marginTop:marginTop}}>
                {!this.state.contentLoaded ? <img onMouseDown={this.onMouseDown} className='buffer-image' src={cStory.bufferImage} alt="story"></img> : <div/> }
                
                <ReactPlayer 
                ref={this.video}
                url={cStory.file} 
                loop={false} 
                controls={false} 
                muted={false} 
                playing={!this.state.paused} 
                playsinline={true}
                progressInterval={100}
                width={w}
                height={h}
                onBuffer={this.onBuffer}
                onBufferEnd={this.onLoadComplete}
                
                config={{
                  file:{
                    attributes:{
                      autoPlay:true,
                      preload: 'none'
                    }
                  }
                }
                
                }
                onProgress={(evt)=>{
                  if(evt.played < 1)
                  {
                    if(evt.played > this.state.currentPer)
                    {
                      if(!this.state.contentLoaded && evt.played > 0.001)
                        this.setState({currentPer:evt.played , contentLoaded:true});
                      else
                        this.setState({currentPer:evt.played});
                    }
                      
                  }
                  else if(!this.state.paused)
                  {
                    this.endStory();
                    
                  }
                    
                }
              }/>
              </div>
          )
      default:
        return <div></div>
    }
 }

}
