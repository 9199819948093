import '../styles/style.scss';
import gradient from '../assets/svg/gradient.svg'

export const CarouselItem = (props)=>{
  
  return (
    <div onClick={props.onSelectHandler} className='carousel-item'>

      <img className='carousel-item-image' src={props.carouselImage} alt="carousel"></img>
      <img className='carousel-item-gradient' src={gradient} alt="carousel gradient"></img>
      <div className='carousel-item-footer'>
        <div className='carousel-item-content'>
          <h2 className='title'>{props.name}</h2>
          <p className='description'>{props.description}</p>
          <p className='times'>{props.weekDay === 'closed' ? props.weekDay :   `Open from ${props.weekDay}`}</p>
        </div>
        <img className='bt-info' src={props.thumb} alt='bt-info'></img>
      </div>
      
    </div>
  )

}