import '../styles/style.scss';
import React from 'react';
import { MapMarker } from './MapMarker';
import { stores } from '../data/Stores';
import {map} from '../utils/utils'
import gsap , {Quad} from 'gsap';

import mapImage from '../assets/svg/map.svg'

export default class Map extends React.Component {

  mapContainer = React.createRef();
  state = {x:0 , y:0}

  componentDidMount = ()=>{
    this.moveMap(stores[this.props.currentPoint].location , 0);
  }

  updateLocation(point){
    this.moveMap(stores[point].location);
  }

  moveMap = (location , time)=>{

    const w = window.innerWidth;
    const h = window.innerHeight;
    const px = -location.x + (w * 0.25)
    const py = -location.y + (h * (h > 700 ? 0.25 : 0.2))

    const dx = this.state.x - px;
    const dy = this.state.y - py;

    const d = Math.sqrt(dx * dx + dy * dy);

    if(time === undefined)
      time = map(d , 180 , 3000 , 1 , 1.5);

    this.setState({x:px,y:py})
    
    gsap.to(this.mapContainer.current , {
      duration:time,
      x:px,
      y:py,
      ease:Quad.easeInOut
    })

  }


 render(){
   return (
     <div className='map'>
      <div className='map-location'>
        <div className='map-location-content'>
          <p>MAP - PARIS</p>
          <p className="map-location-address">{stores[this.props.currentPoint].locationName}</p>
        </div>
      </div>
       <div ref={this.mapContainer} className='map-container'>
         {this.getStores()}
         <img className='map-design' src={mapImage} alt='map'></img>
       </div>
     </div>
   )
 }
 
 getStores = ()=>{
   return stores.map((s , i) =>{
      return <MapMarker icon={s.icon} selected={this.props.currentPoint === i ? true : false} name={s.name} location={s.location} key={s.name} />
   });
 }

}


