import '../styles/style.scss';
import React from 'react';
import { stores } from '../data/Stores';
import Carousel from 'nuka-carousel';
import {CarouselItem} from './CarouselItem'
import gsap from 'gsap';
import arrow from '../assets/svg/arrow.svg'


export default class CarouselContainer extends React.Component {


container = React.createRef(null);
arrowLeft = React.createRef(null);
arrowRight = React.createRef(null);
carousel = React.createRef(null);

onLocationChange = (slideIndex)=>{

  if(slideIndex ===  stores.length - 1)
    gsap.to(this.container.current , {x:'-5%' , duration:0.4});
  else
    gsap.to(this.container.current , {x:0 , duration:0.3});
  
  let arrowL = slideIndex === 0 ? 0.3 : 1;
  let arrowR = slideIndex ===  stores.length - 1 ? 0.3 : 1;

  gsap.to(this.arrowLeft.current , {opacity:arrowL , duration:0.2})
  gsap.to(this.arrowRight.current , {opacity:arrowR , duration:0.2})
  

  this.props.onLocationChange(slideIndex);
}



  
 render(){
   return (
     <div className='carousel'>
       <div className='content'>
         <div className='carousel-content-header'>
           <p className='carousel-content-header-title'>EXPLORE THE STORIES</p>
           <p className='carousel-content-header-amount'>{`[${stores.length}]`}</p>
         </div>
         <div ref={this.container} className='carousel-container'>
          <Carousel
            ref={this.carousel}
            withoutControls={true} 
            cellSpacing={5}
            slidesToShow={1.1}
            cellAlign={'center'}
            slidesToScroll={1}
            heightMode={'current'}
            slideIndex={this.props.currentPoint}
            afterSlide={(slideIndex)=>{
              this.onLocationChange(slideIndex);
            }}
          >
          {this.getItems()}
          </Carousel>
         </div>
         <div className='arrow-container'>
            <img ref={this.arrowLeft} className='arrow-left' src={arrow} onClick={()=>{
              this.carousel.current.previousSlide();
            }} alt="arrow left"></img>
            <img ref={this.arrowRight} className='arrow-right' src={arrow} onClick={()=>{
              this.carousel.current.nextSlide();
            }} alt="arrow right"></img>
          </div>
       </div>
     </div>
   )
 }

 getItems = ()=>{
    return stores.map(s =>{
      
      const openTime = s.openTimes[new Date().getDay()] ;
      return <CarouselItem 
        onSelectHandler={this.props.onSelectHandler} 
        name={s.name} description={s.address} 
        thumb={s.thumb} 
        carouselImage={s.carouselImage} 
        key={s.name}
        weekDay={openTime.close ? 'closed' : `${openTime.start} - ${openTime.end}`}
        />
    })
 }
}
