import store1Icon from '../assets/svg/ofr-icon.svg'
import store2Icon from '../assets/svg/dinand-icon.svg'
import store3Icon from '../assets/svg/classic-icon.svg'

import carouselImage01 from '../assets/carousel/01.jpg'
import carouselImage02 from '../assets/carousel/02.jpg'
import carouselImage03 from '../assets/carousel/03.jpg'

import carouselImageT01 from '../assets/carousel/01_thumb.png'
import carouselImageT02 from '../assets/carousel/02_thumb.png'
import carouselImageT03 from '../assets/carousel/03_thumb.png'

import story01frame01 from '../assets/stories/01/Story-01-01.jpg' 
//import story01frame02 from '../assets/stories/01/OFF_WHITE_OFR.mp4'
import story01frame02 from '../assets/stories/01/Story-01-02.mp4' 
import story01frame03 from '../assets/stories/01/Story-01-03.jpg' 
import story01frame04 from '../assets/stories/01/Story-01-04.mp4' 
import story01frame05 from '../assets/stories/01/Story-01-05.jpg' 
import story01frame06 from '../assets/stories/01/Story-01-06.mp4' 
import story01frame07 from '../assets/stories/01/Story-01-07.jpg'
//import story01frame08 from '../assets/stories/01/Story-01-08.mp4'
import story01frame09 from '../assets/stories/01/Story-01-09.jpg'
import story01frame10 from '../assets/stories/01/Story-01-10.mp4'

import story02frame01 from '../assets/stories/02/Story-02-01.jpg' 
import story02frame02 from '../assets/stories/02/Story-02-02.mp4' 
import story02frame03 from '../assets/stories/02/Story-02-03.jpg' 
//import story02frame04 from '../assets/stories/02/Story-02-04.mp4' 
import story02frame05 from '../assets/stories/02/Story-02-05.jpg'
//import story02frame06 from '../assets/stories/02/Story-02-06.mp4'
import story02frame07 from '../assets/stories/02/Story-02-07.jpg'
import story02frame08 from '../assets/stories/02/Story-02-08.mp4'
import story02frame09 from '../assets/stories/02/Story-02-09.jpg' 

import story03frame01 from '../assets/stories/03/Story-03-01.jpg' 
import story03frame02 from '../assets/stories/03/Story-03-02.mp4' 
import story03frame03 from '../assets/stories/03/Story-03-03.jpg' 
import story03frame04 from '../assets/stories/03/Story-03-04.mp4' 
import story03frame05 from '../assets/stories/03/Story-03-05.jpg' 
//import story03frame06 from '../assets/stories/03/Story-03-06.mp4' 
import story03frame07 from '../assets/stories/03/Story-03-07.jpg'
import story03frame08 from '../assets/stories/03/Story-03-08.mp4'
import story03frame09 from '../assets/stories/03/Story-03-09.jpg'
import story03frame10 from '../assets/stories/03/Story-03-10.jpg'

import story01frame02Buffer from '../assets/stories/01/Story-01-02.jpg'
import story01frame04Buffer from '../assets/stories/01/Story-01-04.jpg'
import story01frame06Buffer from '../assets/stories/01/Story-01-06.jpg'
import story01frame10Buffer from '../assets/stories/01/Story-01-10.jpg'
import bufferImage from '../assets/stories/01/Story-01-02.jpg'


import story02frame02Buffer from '../assets/stories/02/Story-02-02.jpg'
import story02frame08Buffer from '../assets/stories/02/Story-02-08.jpg'


import story03frame02Buffer from '../assets/stories/03/Story-03-02.jpg'
import story03frame04Buffer from '../assets/stories/03/Story-03-04.jpg'
import story03frame08Buffer from '../assets/stories/03/Story-03-08.jpg'

export const stores = [
  {
    name:'OFR Bookshop',
    icon:store1Icon,
    locationName:'3rd ArrondisSement',
    address:`20 Rue Dupetit-Thouars, 75003`,
    carouselImage:carouselImage01,
    thumb:carouselImageT01,
    openTimes:[
      {start:'10AM', end:'8PM', close:true},
      {start:'10AM', end:'8PM', close:false},
      {start:'10AM', end:'8PM', close:false},
      {start:'10AM', end:'8PM', close:false},
      {start:'10AM', end:'8PM', close:false},
      {start:'10AM', end:'8PM', close:false},
      {start:'10AM', end:'8PM', close:true},
    ],
    stories:
    [
      {file:story01frame01 , type:'image', bufferImage:''},{file:story01frame02 , type:'video', bufferImage:story01frame02Buffer},{file:story01frame03 , type:'image', bufferImage:''}
      ,{file:story01frame04 , type:'video', bufferImage:story01frame04Buffer},{file:story01frame05 , type:'image', bufferImage:''},{file:story01frame06 , type:'video', bufferImage:story01frame06Buffer}
      ,{file:story01frame07 , type:'image', bufferImage:''},{file:story01frame09 , type:'image', bufferImage:''}
      ,{file:story01frame10 , type:'video', bufferImage:story01frame10Buffer}
    ],
    location:{
      x:3840,
      y:950
    }
  },
  {
    name:'Dinand par Ferdi',
    icon:store2Icon,
    locationName:'8TH ArrondisSement',
    address:`18 Rue du Boccador, 75008`,
    carouselImage:carouselImage02,
    thumb:carouselImageT02,
    openTimes:[
      {start:'12', end:'10pm', close:true},
      {start:'12', end:'10pm', close:false},
      {start:'12', end:'10pm', close:false},
      {start:'12', end:'10pm', close:false},
      {start:'12', end:'10pm', close:false},
      {start:'12', end:'10pm', close:false},
      {start:'12', end:'10pm', close:true},
    ],
    stories:
    [
      {file:story02frame01 , type:'image', bufferImage:''},{file:story02frame02 , type:'video', bufferImage:story02frame02Buffer},{file:story02frame03 , type:'image', bufferImage:''}
      ,{file:story02frame05 , type:'image', bufferImage:''}
      ,{file:story02frame07 , type:'image', bufferImage:''},{file:story02frame08 , type:'video', bufferImage:story02frame08Buffer},{file:story02frame09 , type:'image', bufferImage:''}
    ],
    location:{
      x:300,
      y:800
    }
  },
  {
    name:'CLASSIC',
    icon:store3Icon,
    locationName:'2ND ArrondisSement',
    address:`25 Pass. du Ponceau, 75002`,
    carouselImage:carouselImage03,
    thumb:carouselImageT03,
    openTimes:[
      {start:'12', end:'7PM', close:true},
      {start:'12', end:'7PM', close:false},
      {start:'12', end:'7PM', close:false},
      {start:'12', end:'7PM', close:false},
      {start:'12', end:'7PM', close:false},
      {start:'12', end:'7PM', close:false},
      {start:'12', end:'7PM', close:true},
    ],
    stories:[
      {file:story03frame01 , type:'image', bufferImage:''},{file:story03frame02 , type:'video', bufferImage:story03frame02Buffer},{file:story03frame03 , type:'image', bufferImage:''}
      ,{file:story03frame04 , type:'video', bufferImage:story03frame04Buffer},{file:story03frame05 , type:'image', bufferImage:''}
      ,{file:story03frame07 , type:'image', bufferImage:''},{file:story03frame08 , type:'video', bufferImage:story03frame08Buffer},{file:story03frame09 , type:'image', bufferImage:''}
      ,{file:story03frame10 , type:'image', bufferImage:''}
    ],
    location:{
      x:3330,
      y:675
    }
  }
]