import '../styles/style.scss';
import markerNormal from '../assets/svg/marker-normal.svg'
import markerSelected from '../assets/svg/marker-selected.svg'
import { useEffect , useRef } from 'react';

import gsap from 'gsap';

export const MapMarker = (props)=>{

  let marker = useRef(null);

  useEffect(() => {
    
    
    gsap.to(marker.current , {
      duration:0,
      x:props.location.x,
      y:props.location.y
    })
  });

  return (
    <div ref={marker} className='map-marker'>
      <img className='marker-icon' src={props.selected ? markerSelected :  markerNormal} alt='marker'></img>
      <p className='marker-name'>{props.name}</p>
    </div>
  )

}