import '../styles/style.scss';
import React from 'react';
import OverlayTimerMarker from './OverlayTimerMarker';


export default class OverlayTimer extends React.Component {

  div = React.createRef(null);

  componentDidMount = ()=>{
    
  }


  render =()=>{
    return (
      <div ref={this.div} className='overlay-timer'>
        {this.getTimerItems()}
      </div>
    )
  }

  getTimerItems = ()=>{
    
    if(!this.div.current)return;
  
  const width = window.innerWidth * 0.8;
  const w =  width / this.props.stories.length;
   return this.props.stories.map((s , i) =>{

    let per = this.props.currentPer;
    if(i < this.props.currentStory)
      per = 1;
    else if(i > this.props.currentStory)
      per = 0;
       
    return <OverlayTimerMarker width={w} per={per} key={i}/>

   })
  }

  

}

